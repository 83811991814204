// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = (window.innerHeight && document.documentElement.clientHeight ? Math.min(window.innerHeight, document.documentElement.clientHeight) : window.innerHeight || 
document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight) * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);
// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = (window.innerHeight && document.documentElement.clientHeight ? Math.min(window.innerHeight, document.documentElement.clientHeight) : window.innerHeight || 
document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight) * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});
window.addEventListener('DOMContentLoaded', () => {
  // We execute the same script as before
  let vh = (window.innerHeight && document.documentElement.clientHeight ? Math.min(window.innerHeight, document.documentElement.clientHeight) : window.innerHeight || 
document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight) * 0.01;
  setTimeout(function(){ document.documentElement.style.setProperty('--vh', `${vh}px`); }, 200);
  setTimeout(function(){ document.documentElement.style.setProperty('--vh', `${vh}px`); }, 500);
  setTimeout(function(){ document.documentElement.style.setProperty('--vh', `${vh}px`); }, 1000);
  setTimeout(function(){ document.documentElement.style.setProperty('--vh', `${vh}px`); }, 2000);
});

jQuery(document).ready(function ($) {
  function header_change() {
    if ($(window).scrollTop() < 20) {
      $('header').removeClass('small');
    } else {
      $('header').addClass('small');
    }
  }
  header_change();

  function fillContactForm() {
    if ($('.section.head h1')) {
      let title = $('.section.head h1').text();
      $('section.contacts textarea').val(title);
    }
  }
  setTimeout(fillContactForm, 3000);
  
  $(window).scroll(function () {
    header_change();
  });

  $('.top').on('click', function (e) {
    if ($(this).hasClass('active')) {
      if (window.innerWidth < 1024) {
        $(this).removeClass('active');
      }
    } else {
      e.preventDefault();
      $('body').addClass('modal_lock');
      $('.top').removeClass('active');
      $(this).addClass('active');
      $('#close_menu').addClass('active');
      if (window.innerWidth > 1024) {
        //set height of child menu
        let top = $('header').height();
        $(this)
          .find('>.sub-menu')
          .css('top', top + 'px');
        $(this)
          .find('>.sub-menu')
          .css('height', 'calc(100vh - ' + top + 'px)');
      } else {
        // console.log('small screen');
      }
    }
  });

  $('.top .sub-menu a, .modal_main, .modal_thanks, .sub-menu .menu-item-has-children').on(
    'click',
    function (e) {
      e.stopPropagation();
    }
  );
  $('.sub-menu .menu-item-has-children').on('click', function (e) {
    $(this).toggleClass('active');
  });

  function close_menu() {
    $('#close_menu').removeClass('active');
    $('.top').removeClass('active');
    $('.header_menu').removeClass('active');
    $('.hamburger').removeClass('hidden_smooth');
    $('body').removeClass('modal_lock');
  }

  $('#close_menu').on('click', function () {
    close_menu();
  });

  document.addEventListener('keydown', function (event) {
    if (event.key === 'Escape') {
      close_menu();
      close_under_attack();
    }
  });

  $('.dont_panic .btn').on('click', function (e) {
    setTimeout(() => {
      $('body').addClass('modal_lock');
    }, 100);
    $('.modal_box').removeClass('hidden');
    $('.modal_box .modal_main').removeClass('hidden');
  });

  function close_under_attack() {
    $('body').removeClass('modal_lock');
    $('.modal_box, .header .modal_main, .modal_box .modal_main, .modal_box .modal_thanks').addClass(
      'hidden'
    );
  }

  $('.close_modal, .modal_box, .modal_box .modal_check_success').on('click', function (e) {
    close_under_attack();
  });

  document.addEventListener(
    'wpcf7mailsent',
    function (event) {
      $('.modal_main').addClass('hidden');
      const thanks = $('.modal_thanks');
	  thanks.removeClass('hidden');
	  thanks[thanks.length - 1].scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
    },
    false
  );

  function getOfferOptions() {
    // Set preferred slidesToShow
    // default min with 176px
    let clientsSlidesToShow = Math.floor(window.innerWidth / 176);
    if (clientsSlidesToShow % 2 === 0) {
      clientsSlidesToShow = clientsSlidesToShow - 1;
    }
    let clientsChildElements = $('.offer_box').children().length;
    // Check if we can fulfill the preferred slidesToShow
    if (clientsSlidesToShow > clientsChildElements - 1) {
      // Otherwise, make slidesToShow the number of slides - 1
      // Has to be -1 otherwise there is nothing to scroll for - all the slides would already be visible
      clientsSlidesToShow = clientsChildElements - 1;
      if (clientsSlidesToShow < 1) clientsSlidesToShow = 1;
    }
    return {
      rows: 1,
      centerMode: false,
      slidesToShow: clientsSlidesToShow,
      slidesToScroll: 1,
      swipeToSlide: true,
      autoplay: true,
      dots: true,
      appendDots: $('.offer_dots'),
      arrows: true,
      prevArrow: $('.offer_prev'),
      nextArrow: $('.offer_next'),
      infinite: true,
      speed: 500,
    };
  }

  $('.offer_box').slick(getOfferOptions());

  $('.feedback_box').slick({
    rows: 1,
    centerMode: false,
    slidesToShow: 1,
    swipeToSlide: true,
    slidesToScroll: 1,
    autoplay: true,
    dots: false,
    arrows: true,
    prevArrow: $('.feedback_prev'),
    nextArrow: $('.feedback_next'),
    infinite: true,
    speed: 500,
  });

  if (window.innerWidth < 1024) {
    $('.certificates_box').slick({
      rows: 1,
      centerMode: false,
      slidesToShow: 3,
      swipeToSlide: true,
      slidesToScroll: 1,
      autoplay: true,
      dots: true,
      appendDots: $('.cert_dots'),
      arrows: true,
      prevArrow: $('.cert_prev'),
      nextArrow: $('.cert_next'),
      infinite: true,
      speed: 500,
    });

    $('.news .news_box').slick({
      rows: 1,
      centerMode: false,
      slidesToShow: 1,
      swipeToSlide: true,
      slidesToScroll: 1,
      autoplay: true,
      dots: false,
      appendDots: false,
      arrows: true,
      prevArrow: $('.news_prev'),
      nextArrow: $('.news_next'),
      infinite: true,
      speed: 500,
    });

    $('.team_box').slick({
      rows: 1,
      centerMode: false,
      slidesToShow: 1,
      swipeToSlide: true,
      slidesToScroll: 1,
      autoplay: true,
      dots: true,
      appendDots: $('.team_dots'),
      arrows: true,
      prevArrow: $('.team_prev'),
      nextArrow: $('.team_next'),
      infinite: true,
      speed: 500,
    });

    $('.cases_box').slick({
      rows: 1,
      centerMode: false,
      slidesToShow: 1,
      swipeToSlide: true,
      slidesToScroll: 1,
      autoplay: false,
      dots: false,
      appendDots: false,
      arrows: true,
      prevArrow: $('.cases_prev'),
      nextArrow: $('.cases_next'),
      infinite: true,
      speed: 500,
    });
  }

  $('.security_box .item').on('click', function (e) {
    if (window.innerWidth > 1024) {
      e.preventDefault();
      let item = $(this).attr('data-item');
      $('.item, .info').removeClass('active');
      $('[data-item="' + item + '"]').addClass('active');
    }
  });

  $('#accept_cookie').on('click', function (e) {
    $('.cookie').slideUp('smooth');
    $.ajax({
      url: '/wp-admin/admin-ajax.php',
      type: 'POST',
      data: 'action=cookieAccept',
      success: function () {
        $('.cookie').slideUp('smooth');
        // activate the disabled gtm/ga scripts
        $('script[data-block-on-consent]').each((i, el) => { eval(el.innerText); });
      },
    });
  });

  $('.partner_type_group_box .partner_type').on('click', function (e) {
    $('.partner_type_group_box .partner_type').removeClass('active');
    $(this).addClass('active');
    $('html, body').animate(
      {
        scrollTop: $(this).offset().top - 170,
      },
      1000
    );
  });

  $('.accordion0_group_box .accordion0 .accordion0_title').on('click', function (e) {
    $('.accordion0_group_box .accordion0').removeClass('active');
    this.closest('.accordion0').classList.add('active');
    var headerMenu = 65;
    var headerHeight = $('header').innerHeight();
    if ($(window).width() > 1024) {
      headerMenu = $('.header_menu').innerHeight() + 10;
    }
    $('html, body').animate(
      {
        scrollTop: $(this).offset().top - (headerHeight + headerMenu),
      },
      1000
    );
  });

  $('.differences_box .difference').on('click', function (e) {
    if (window.innerWidth < 1024) {
      $('.differences_box .difference').removeClass('active');
      $(this).addClass('active');
      $('html, body').animate(
        {
          scrollTop: $(this).offset().top - 100,
        },
        1000
      );
    }
  });

  $('.hamburger').on('click', function () {
    $(this).addClass('hidden_smooth');
    $('body').addClass('modal_lock');
    $('.header_menu').addClass('active');
    $('#close_menu').addClass('active');
    let vh = (window.innerHeight && document.documentElement.clientHeight ? Math.min(window.innerHeight, document.documentElement.clientHeight) : window.innerHeight || 
    document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight) * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);    
  });
  $('.header_menu').on('click', function () {
    $(this).removeClass('active');
    $('body').removeClass('modal_lock');
    $('.hamburger').removeClass('hidden_smooth');
  });
  $('.header_menu .menu_list').on('click', function (e) {
    e.stopPropagation();
  });

  $('.header_menu .menu_list .top>.sub-menu li').on('mouseover touchstart', function (e) {
    const _this = $(this);
    const sub = _this.find('.sub-menu');
    if (sub.length && window.innerWidth > 1024) {
      let menuTop = $('body').position().top;
      let parentHeight = 0;
      _this
        .closest('.sub-menu')
        .children()
        .each(function (i, child) {
          parentHeight += $(child).outerHeight();
        });
      let thisTop = _this.position().top;
      let top = menuTop - thisTop + 20; //+ menu padding

      console.log(parentHeight);

      _this.find('>.sub-menu').css('top', top + 'px');
      _this.find('>.sub-menu').css('height', parentHeight);
    } else {
      _this.find('>.sub-menu').removeAttr('style');
    }
    $(window).on('resize orientationchange', function () {
      if (sub.length && window.innerWidth > 1024) {
        let menuTop = $('body').position().top;
        let parentHeight = 0;
        _this
          .closest('.sub-menu')
          .children()
          .each(function (i, child) {
            parentHeight += $(child).outerHeight();
          });
        let thisTop = _this.position().top;
        let top = menuTop - thisTop + 20; //+ menu padding

        _this.find('>.sub-menu').css('top', top + 'px');
        _this.find('>.sub-menu').css('height', parentHeight);
      } else {
        _this.find('>.sub-menu').removeAttr('style');
      }
    });

  });

  $(window).on('resize orientationchange', function () {
    $('.offer_box').slick('unslick').slick(getOfferOptions());
  });


  // Inweb
  $('.to-contact-form').on('click', function(){
    let dataText = $(this).attr('data-text');
    if(dataText !== undefined) {
      let title = $('.section.head h1').text();
      $('section.contacts textarea').val(title + '. ' + dataText);
    }
  });
  $('[href="#contact_us"]:not(.to-contact-form)').on('click', function(){
    let dataText = $(this).text();
    if(dataText !== undefined) {
      let title = $('.section.head h1').text();
      $('section.contacts textarea').val(title + '. ' + dataText);
    }
  });



  // Load more posts
  let postCurrentPage = 1;
  $('#load-more').on('click', function() {
    postCurrentPage++; 
    $(this).addClass('loading');
    $.ajax({
      type: 'POST',
      url: '/wp-admin/admin-ajax.php',
      dataType: 'html',
      data: {
        action: 'post_load_more',
        paged: postCurrentPage,
        perpage: 4,
        author_id: $(this).attr('data-author-id'),
      },
      success: function (res) {
        $('.post_box').append(res);
        $('#load-more').removeClass('loading');
        if(res == '') {
          $('#load-more').hide();
        }
      }
    });
  });

});
